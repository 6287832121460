<template>
	<ion-footer>
		<ion-toolbar />
	</ion-footer>
</template>

<style lang="sass">
ion-footer
	ion-toolbar
		--border-style: none
		--min-height: 0
		height: 0
		--background: var(--ion-item-background, var(--ion-background-color, #fff))
</style>

