<template>
	<ion-page>
		<A-header :title="list.title" :defaultHref="{name: 'Lists'}" :enable-delete="true" @on-delete="deleteList()" @on-title-click="renameList()" />
		
		<ion-content :fullscreen="true">
			<A-collapse-header :title="list.title" @click="renameList()" />
		
			<ion-list lines="none">
				
				<ion-item-sliding v-for="item in list.items" :key="`item-${item.id}`">
					<ion-item>
						<div slot="start" style="position: relative">
							<ion-checkbox @update:modelValue="toggleItemCheck($event, item.id)" :modelValue="item.is_checked" />
						</div>

						<ion-label>{{ item.name }}</ion-label>
						<ion-badge @click.prevent="changeValueAndUnit(item.id)">{{ item.value }} {{ item.unit }}</ion-badge>
					</ion-item>

					<ion-item-options side="end">
						<ion-item-option color="danger" @click="deleteItem(item.id)">
							<ion-icon slot="icon-only" :icon="trashOutline" class="x"></ion-icon>
						</ion-item-option>
					</ion-item-options>
				</ion-item-sliding>

				<ion-item>
					<div slot="start" style="position: relative">
						<ion-checkbox disabled/>
					</div>

					<ion-input v-model="newItemName" @keydown.enter="addItem()" placeholder="New item" />
				</ion-item>

			</ion-list>
		</ion-content>

		<A-footer-safearea />
	</ion-page>
</template>
<script>
import AHeader 			from '@/plugins/appshoppinglist/layout/a-header.vue'
import ACollapseHeader 	from '@/plugins/appshoppinglist/layout/a-collapse-header.vue'
import AFooterSafearea  from '@/plugins/appshoppinglist/layout/a-footer-safearea.vue'
import { trashOutline} 	from 'ionicons/icons'
import { pickerController, alertController } from "@ionic/vue"

export default {
	components: {
		AHeader,
		ACollapseHeader,
		AFooterSafearea
	},

	data() {
		return {
			trashOutline,
			newItemName: '',
			valueColumn: [
				{ text: "1", value: 1 },
				{ text: "2", value: 2 },
				{ text: "3", value: 3 },
				{ text: "4", value: 4 },
				{ text: "5", value: 5 },
				{ text: "6", value: 6 },
				{ text: "7", value: 7 },
				{ text: "8", value: 8 },
				{ text: "9", value: 9 },
				{ text: "10", value: 10 },
				{ text: "50", value: 50 },
				{ text: "100", value: 100 },
				{ text: "200", value: 200 },
				{ text: "300", value: 300 },
				{ text: "400", value: 400 },
				{ text: "500", value: 500 },
				{ text: "600", value: 600 },
				{ text: "700", value: 700 },
				{ text: "800", value: 800 },
				{ text: "900", value: 900 },
				{ text: "1000", value: 1000 }
			],
			unitColumns: [
				{ text: "Package", value: "package" },
				{ text: "Piece", value: "piece" },
				{ text: "Grams", value: "grams" },
				{ text: "Kilograms", value: "kilograms" }
			]
		}
	},

	computed: {
		list() {
			return this.$store.getters['shoppingList/lists'].find(list => list.id == this.$route.params.id) || {}
		}
	},

	methods: {
		async renameList() {
			const alert = await alertController.create({
				header: 'Rename list',
				inputs: [{
					name: 'title',
					type: 'text',
					placeholder: 'Family dinner',
				}],
				buttons: [{
					text: 'Cancel',
					role: 'cancel'
				},
				{
					text: 'Create',
					handler: async(value) => {
						const list = this.list
						list.title = value.title.length ? value.title : 'Unnamed list'
						await this.updateList(list)
					}
				}]
			})

			return alert.present()
		},

		updateList(list) {
			this.$store.dispatch('shoppingList/updateList', list)
		},

		async deleteList() {
			const alert = await alertController.create({
				header: 'Are you sure?',
				buttons: ['Cancel', 'Yes'],
			})

			await alert.present()

			const { role } = await alert.onDidDismiss()
			if (role == 'cancel') return

			this.$router.go(-1)
			this.$store.dispatch('shoppingList/deleteList', this.$route.params.id)
		},

		addItem() {
			if (!this.newItemName.length) return
			
			this.$store.dispatch('shoppingList/createItem', {
				listId: this.$route.params.id,
				item: {
					name: this.newItemName,
					value: 1,
					unit: "piece",
					is_checked: false
				}
			})

			this.newItemName = ''
		},

		toggleItemCheck(value, itemId) {
			this.$store.dispatch('shoppingList/updateItem', {
				listId: this.$route.params.id,
				item: {
					id: itemId,
					is_checked: value
				}
			})
		},

		editItem(itemId, value, unit) {
			this.$store.dispatch('shoppingList/updateItem', {
				listId: this.$route.params.id,
				item: {
					id: itemId,
					value: value.value,
					unit: unit.value
				}
			})
		},

		deleteItem(itemId) {
			this.$store.dispatch('shoppingList/deleteItem', {
				listId: this.$route.params.id,
				itemId: itemId
			})
		},

		async changeValueAndUnit(itemId) {
			const item = this.list.items.find(item => item.id == itemId)

			let valueIndex
			let unitIndex
			
			this.valueColumn.forEach((option, index) => {
				if (option.value == item.value) valueIndex = index
			})

			this.unitColumns.forEach((option, index) => {
				if (option.value == item.unit) unitIndex = index
			})

			const picker = await pickerController.create({
				columns: [{
					name: "value",
					selectedIndex: valueIndex,
					options: this.valueColumn
				}, {
					name: "unit",
					selectedIndex: unitIndex,
					options: this.unitColumns
				}],
				buttons: [{
					text: "Cancel",
					role: "cancel",
				},
				{
					text: "Confirm",
					handler: (value) => {
						this.editItem(item.id, value.value, value.unit)
					}
				}]
			})

			picker.columns.forEach(column => {
				column.options.forEach(element => {
					delete element.selected
					delete element.duration
					delete element.transform
				})
			})

			await picker.present()
		}
	}
}
</script>

<style lang="sass" scoped>
ion-list
	margin-top: 10px
	margin-bottom: 15px

	ion-input
		&.has-focus
			border-bottom: 1px solid var(--ion-color-primary)

</style>