<template>
	<ion-header :translucent="true">
		<ion-toolbar>
			<ion-buttons slot="start">
				<ion-back-button v-if="defaultHref" :default-href="defaultHref" :text="text" />
				<ion-menu-button v-else color="primary"></ion-menu-button>
			</ion-buttons>

			<ion-title @click="$emit('onTitleClick')">{{ title }}</ion-title>

			<ion-buttons slot="end">
				<ion-button @click="$emit('onDelete')" v-if="enableDelete">
					<ion-icon :icon="trashOutline" class="delete" />
				</ion-button>

				<ion-button @click="$emit('onNew')" v-if="enableNew">
					<ion-icon :icon="addCircleOutline" />
				</ion-button>
			</ion-buttons>
		</ion-toolbar>
	</ion-header>
</template>

<script>
import { trashOutline, addCircleOutline } from 'ionicons/icons'

export default {
	props: {
		title: {
			type: String
		},

		text: {
			type: String,
			default: "Back"
		},

		defaultHref: {
			type: Object
		},

		enableDelete: {
			type: Boolean,
			default: false
		},

		enableNew: {
			type: Boolean,
			default: false
		}
	},

	data() {
		return {
			trashOutline,
			addCircleOutline
		}
	}
}
</script>

<style lang="sass">
ion-header:not(.header-collapse-condense-inactive)
	ion-back-button::part(text)
		transition-duration: 200ms
		opacity: 0

ion-icon.delete
	color: var(--ion-color-danger)
</style>

