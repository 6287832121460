<template>
	<ion-header collapse="condense" class="collapse-header ion-no-border">
		<ion-toolbar>
			<ion-title size="large">{{ title }}</ion-title>
		</ion-toolbar>
	</ion-header>
</template>

<script>
export default {
	props: {
		title: {
			type: String,
			required: true
		}
	}
}
</script>